import React, { FC, useEffect, useRef, useState } from "react"

import { Responsive, WidthProvider } from "react-grid-layout"

import Card from "./components/Card"
import Heading from "./components/Heading"
import AddBlockModal from "./components/modal/AddBlockModal"
import EditBlockModal from "./components/modal/EditBlockModal"
import styles from "./Dashboards.module.scss"
import { defaultGroupings, defaultMetrics } from "../../app/providers/redux/slices/reports/table/tableSlice"
import MainLayout from "../../shared/common/components/layouts/MainLayout"
import Loader from "../../shared/common/components/loader/Loader"
import PageHead from "../../shared/common/components/page-header/PageHeader"
import UniversalReportTitle from "../../shared/common/components/reports/dashboards/title/UniversalReportTitle"
import Params from "../../shared/common/components/reports/params/Params"
import { useActions } from "../../shared/common/hooks/useActions"
import { useTypedSelector } from "../../shared/common/hooks/useTypedSelector"

import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import Table from "../../shared/common/components/reports/dashboards/Table"

import { Button, Dropdown, Menu, Space, Switch, Tooltip } from "antd"
import { ArrowsAltOutlined, EllipsisOutlined, QuestionCircleOutlined } from "@ant-design/icons"

const ResponsiveGridLayout = WidthProvider(Responsive)
import Chart from "../../shared/common/components/reports/dashboards/Chart"
import useResizeHandler from "../../shared/common/hooks/useResizeObserver"
import ClarityCursorHandGrabLine from "../../shared/common/components/ui/icons/ClarityCursorHandGrabLine"
import TypeIcon from "../../shared/common/components/reports/params/attribution/icons/TypeIcon";
import {dashboardChartDetails} from "../../shared/common/constants/details";
import FirstRegIcon from "../../shared/common/components/reports/params/attribution/icons/FirstRegIcon";
import LastRegIcon from "../../shared/common/components/reports/params/attribution/icons/LastRegIcon";
import FixedUniversalTitle from "../../shared/common/components/reports/dashboards/title/FixedUniversalTitle";
interface GridItemLayout {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    minW?: number;
    minH?: number;
    maxW?: number;
    maxH?: number;
}
export interface DashboardComponent {
    title?: string
    componentType: string
    layoutData: GridItemLayout
    data: any
}


interface DashboardProps {
    isChartVisible: boolean;
}

const attrItems = [
    {
        id: "type_applications",
        name: "Тип регистрации:",
        selectValues: [
            { name: "Нулевые заказы GC", id: "2", icon: null },
            { name: "Регистрации GC", id: "0", icon: null },
            { name: "SCAN", id: "1", icon: null },
            { name: "По умолчанию", id: "blocks", icon: null },
            //{ name: "Ручное добавление", id: "3", icon: null },
        ],
        description: `Выбор типа регистрации по которым нужно создать отчёт:
                    <br><br>
                    Типы регистраци:
                    <br><br>
                    - SCAN - Это данные собираемые скриптом SCAN с форм;<br>
                    - Нулевые заказы GC - Заказы в GetCourse со стоимостью 0 руб.;<br>
                    - Регистрации GC -  Первая регистрация (попадания в базу) в GetCourse;<br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=504" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
        icon: <TypeIcon />,
    },
    {
        id: "attribution",
        name: "Атрибуция:",
        selectValues: [
            { name: "Первая регистрация", id: "1", icon: <FirstRegIcon /> },
            { name: "Последняя регистрация", id: "2", icon: <LastRegIcon /> },
            { name: "По умолчанию", id: "blocks", icon: null },
        ],
        description: `Сервис SCAN позволяет определить к какой регистрации пользователя привязывать заказ. В сервисе 2 типа атрибуции:
                    <br><br>
                    - Первая регистрация <br>
                    - Последняя регистрация <br>
                    <br><br>
                    <a href="https://help.scananalytics.ru/?p=508" rel="noopener noreferrer" target="_blank">Подробнее</a>.
                    `,
        icon: null,
    },
]


const Dashboards: FC = () => {
    const [ addBlockVisible,setAddBlockVisible ] = useState({
        visible:false,
        blockType:""
    })
    const [ editBlockState,setEditBlockState ] = useState({
        visible:false,
        id:"",
        data:{}
    })
    const [ isEditMode,setEditMode ] = useState(false)
    const { isFixedHeader } = useTypedSelector((state)=>state.dashboard)
    useEffect(() => {
        document.body.style.overflow = "scroll"
        document.body.style.overflowX = "auto"
    },[])
    const { isChartLoading, isTableLoading } = useTypedSelector(
        (state) => state.meta
    )
    const { filters } = useTypedSelector((state)=>state.filter)
    const [dashboardFilters,setDashboardFilters] = useState(filters)

    const { isChartVisible } = useTypedSelector((state)=>state.chart)
    const [ isUpdated,setIsUpdated ] = useState(false)
    const {
        setFilters,
        setMetrics,
        setGroupings,
        setFiltersTable,
        setCurrentReport,
        setCurrentSegment,
        setSortTable,
        setMetaCompare,
        setSeries,
        setMetricsChecked,
        setMetaChartFetching,
        setAttributesDashboardBlocks,
        setDashboardBlockDetails,
        setDashboardsBlocksInitialState,
        setDashboardsBlocksTableInitialState,
        setCurrentFunnel,
        setDashboards,
        setIsFixedHeader
    } = useActions()
    useEffect(() => {
        setMetaCompare({ value:false })
        setMetrics({ data:defaultMetrics })
        setGroupings({ data:defaultGroupings })
        setFilters([])
        setFiltersTable([])
        setCurrentReport({})
        setSortTable({})
        setCurrentSegment(null)
        setSeries([])
        setTimeout(()=>{
            setMetaChartFetching({ value:true })
        },700)
        setMetricsChecked({ data:[ { label:defaultMetrics[0].label,name:defaultMetrics[0].dataKey } ] })

        return () => {
            setMetaCompare({ value:false })
            setMetrics({ data:defaultMetrics })
            setGroupings({ data:defaultGroupings })
            setFilters([])
            setFiltersTable([])
            setCurrentReport({})
            setSortTable({})
            setCurrentFunnel([])
            setCurrentSegment(null)
            setMetricsChecked({ data:[] })
        }
    }, [])


    const [ dashboardComponents,setDashboardComponents ] = useState<DashboardComponent[]>([
        {
            title: "Компонент таблицы",
            componentType: "table",
            layoutData: {
                i: "1",
                x: 0,
                y: 25,
                w: 12,
                h: 10,
                minW: 2,
                minH: 4,
            },
            data: {
                blockId:"1",
                description:"test",
                date:"reg",
                attribution:{
                    attribution:[ "1" ],
                    type_applications:[ "1" ]
                },
                selectedMetrics:[],
                selectedGroupings:[],
                details:"m"
            }
        },
        {
            title: "Компонент таблицы 2",
            componentType: "table",
            layoutData: {
                i: "2",
                x: 3,
                y: 0,
                w: 12,
                h: 10,
                minW: 3,
                minH: 4,
            },
            data: {
                blockId:"2",
                description:"test",
                date:"reg",
                attribution:{
                    attribution:[ "2" ],
                    type_applications:[ "1" ]
                },
                selectedMetrics:[],
                selectedGroupings:[],
                details:"d"
            }
        },
    ])

    useEffect(() => {
        const dashboardComponentsIds: string[] = dashboardComponents.map((el)=>el.data.blockId)
        setDashboardsBlocksTableInitialState({ ids:dashboardComponentsIds })
        setDashboardsBlocksInitialState(dashboardComponentsIds)
        dashboardComponents.forEach((component, index) => {
            const { attribution, details,blockId } = component.data
            setAttributesDashboardBlocks({
                blockId,
                attribution: attribution?.attribution,
                type_applications: attribution?.type_applications
            })
            setDashboardBlockDetails({
                blockId,
                detail: details
            })
        })
        setIsUpdated(true)
    }, [])
    const layout = dashboardComponents.map(component => ({
        ...component.layoutData
    }))

    const onLayoutChange = (newLayout: GridItemLayout[]) => {
        const updatedComponents = dashboardComponents.map(component => {
            const layoutItem = newLayout.find(item => item.i === component.layoutData.i)
            return layoutItem ? { ...component, layoutData: layoutItem } : component
        })

        setDashboardComponents(updatedComponents)
    }
    useEffect(()=>{
        setDashboards(dashboardComponents)
    },[ dashboardComponents ])
    const handleAddBlock = (newComp:DashboardComponent) => {
        setDashboardComponents((prev)=>[ ...prev,newComp ])
    }
    const handleEditBlock = (updComp: DashboardComponent) => {
        console.log(updComp)
        setDashboardComponents((prevState) => {
            const updCompIndex = prevState.findIndex((comp) => comp.data.blockId === updComp.data.blockId)

            if (updCompIndex !== -1) {
                const newDashboardComponents = [ ...prevState ]
                newDashboardComponents[updCompIndex] = updComp
                console.log(newDashboardComponents, updCompIndex)
                return newDashboardComponents
            }

            return prevState
        })
    }

    console.log(dashboardComponents)
    const handleDelete = (id:string) => {
        setDashboardComponents(dashboardComponents.filter(component => component.layoutData.i !== id))
    }
    const handleEdit = (id:string) => {
        setEditBlockState({
            id,
            visible: true,
            data: dashboardComponents.find((component) => component.layoutData.i === id) || {}
        })
    }


    const menu = (component:any) => (
        <Menu>
            <Menu.Item key="edit" onClick={()=>handleEdit(component.layoutData.i)}>
                <Button type="text">Редактировать</Button>
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(component.layoutData.i)}>
                <Button type="text">Удалить</Button>
            </Menu.Item>
        </Menu>
    )
    useEffect(() => {
        if(editBlockState.visible || addBlockVisible.visible){
            setDashboardFilters(filters)
            return
        }
        if(!editBlockState.visible && !addBlockVisible.visible){
            setFilters(dashboardFilters)
        }
    }, [editBlockState.visible,addBlockVisible.visible]);
    useEffect(() => {
        if(dashboardFilters?.length !== filters?.length && (!editBlockState.visible && !addBlockVisible.visible)){
            setDashboardFilters(filters)
        }
    }, [filters]);
    useEffect(() => {
        localStorage.setItem("dashboardFilters",JSON.stringify(dashboardFilters));
    }, [dashboardFilters]);

    const paramsRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (paramsRef.current) {
                const scrollY = window.scrollY || window.pageYOffset;

                if (scrollY >= 280) {
                    setIsFixedHeader(true);
                } else {
                    setIsFixedHeader(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
    <MainLayout pageTitle="Дашборды">
        <div className="page-wrapper" style={{}}>
            <PageHead
                title="Дашборды"
                description={""}
                reportInfo={true}
            />
            <div className="page-content">
                <Loader loading={isTableLoading || isChartLoading}>
                    {
                        isFixedHeader ? <div ref={paramsRef} style={{
                                position: "fixed",
                                top: 0,
                                zIndex: 99999,
                                background: "white",
                                width: "100%",
                                marginLeft: "-24px",
                                padding: "15px 15px 0px 15px", ...(isFixedHeader ? {
                                    display: "inline-flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    flexWrap: "wrap"
                                } : {})
                            }}>
                                <Params
                                    withCompare={false}
                                    withDetails={true}
                                    withSegments={true}
                                    isFilterVisible={true}
                                    detailsItems={dashboardChartDetails}
                                    attributionItems={attrItems}
                                    filtersFilter={[
                                        "Пользователи",
                                        "Регистрации",
                                        "Шаги воронки",
                                        "Заказы",
                                        "Платежи",
                                        "Вебинары",
                                        "Реклама",
                                        "Просмотр страницы после регистрации"
                                    ]}
                                />
                                <FixedUniversalTitle setAddBlockVisible={setAddBlockVisible}/>
                            </div>
                            :
                            <div ref={paramsRef}>
                                <UniversalReportTitle setAddBlockVisible={setAddBlockVisible}/>
                                <Params
                                    withCompare={false}
                                    withDetails={true}
                                    withSegments={true}
                                    isFilterVisible={true}
                                    detailsItems={dashboardChartDetails}
                                    attributionItems={attrItems}
                                    filtersFilter={[
                                        "Пользователи",
                                        "Регистрации",
                                        "Шаги воронки",
                                        "Заказы",
                                        "Платежи",
                                        "Вебинары",
                                        "Реклама",
                                        "Просмотр страницы после регистрации"
                                    ]}
                                />
                            </div>
                    }
                </Loader>
            </div>
            <div className="page-content"
                 style={{background: "rgb(243, 243, 243)", borderTop: "1px solid #f2f2f2", width: "100%"}}>
                <ResponsiveGridLayout
                    className="layout"
                    layouts={{lg: layout}}
                    breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                    cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                    rowHeight={10}
                    onLayoutChange={(layout) => onLayoutChange(layout)}
                    isDraggable={true}
                    isResizable={true}
                    draggableHandle={`.${styles.dragIcon}`}
                    resizeHandle={<div
                        className={`${styles.resizeHandle}`}
                    >
                        <ArrowsAltOutlined rotate={90}/>
                    </div>}
                    margin={[16, 16]}
                >
                    {
                        isUpdated && dashboardComponents.map((component) => {
                            let ComponentToRender

                            switch (component.componentType) {
                                case "table":
                                    ComponentToRender = Table
                                    break
                                case "number":
                                    ComponentToRender = Card
                                    break
                                case "chart":
                                    ComponentToRender = Chart
                                    break
                                case "heading":
                                    ComponentToRender = () => <></>
                                        break
                                    default:
                                        ComponentToRender = null
                                }
                                const getStyles = () => {
                                    let styles = {}

                                    switch (component.componentType) {
                                        case "table":
                                            styles = {
                                                marginTop:"-24px",
                                                width:"max-content",
                                                // height:"98%"
                                            }
                                            break
                                        case "number":
                                            styles = {
                                                marginTop:"-24px",
                                                height:"100%"
                                            }
                                            break
                                        case "chart":
                                            styles = { marginTop: "-24px", height:"100%",...( component.data?.chartType === "pie" ? { paddingTop:"20px" } : {} )}
                                            break
                                        case "heading":
                                            break
                                        default:
                                            styles = {
                                                marginTop:"-24px"
                                            }
                                    }
                                    return styles
                                }
                                const getStyleForBlock = () => {
                                    let styles = {}
                                    if(component.componentType === "number") {
                                        styles = { overflow:"hidden" }
                                    }
                                    if(component.componentType === "table") {
                                        styles = { overflow:"auto" }
                                    }
                                    return styles
                                }
                                return ComponentToRender ? (
                                    <div
                                        onMouseEnter={() => setEditMode(true)}
                                        onMouseLeave={() => setEditMode(false)}
                                        className={`${styles.componentContainer} ${component.componentType !== "heading" ? styles.smoothComponent : styles.heading} ${component.componentType === "chart" ? "chartComponent" : ""}`}
                                        style={getStyleForBlock()}
                                        id={`component-${component.data.blockId}`}
                                        key={component.layoutData.i}>
                                        <div
                                            className={`${styles.dragIcon} drag-handler`}
                                        >
                                            <ClarityCursorHandGrabLine style={{ height:20,width:20 }} />
                                        </div>
                                        <div className={styles.dropdownContainer}>
                                            <Dropdown overlay={menu(component)} trigger={[ "click" ]}>
                                                <Button type="text" icon={<EllipsisOutlined/>}/>
                                            </Dropdown>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", columnGap: "8px",...(component.componentType === "heading" ? { position:"absolute",top:"10px",overflow:"hidden" } :  { marginTop: "-44px",position:"relative",zIndex:999 }) }}>
                                            <h2 style={{ ...(component.componentType === "heading" && { fontWeight: 800 }),marginBottom:0 }}>{component.title}</h2>
                                            {component.data?.description && (
                                                <Tooltip
                                                    placement="bottom"
                                                    title={
                                                        <div
                                                            className="page-title-tooltip-text"
                                                            dangerouslySetInnerHTML={{ __html: component.data.description }}
                                                        ></div>
                                                    }
                                                    color="#fff"
                                                    zIndex={9999}
                                                    trigger="click"
                                                    overlayClassName="page-title-tooltip"
                                                >
                                                    <QuestionCircleOutlined
                                                        style={{ color: "#ccc", fontSize: 14, marginTop: -5 }}/>
                                                </Tooltip>
                                            )}
                                        </div>
                                        <div style={getStyles()}>
                                            <ComponentToRender {...component.data} isShow={true}/>
                                        </div>
                                    </div>
                                ) : null
                            })
                        }
                    </ResponsiveGridLayout>

                    {addBlockVisible.visible &&
                        <AddBlockModal blockType={addBlockVisible.blockType} visible={addBlockVisible.visible}
                                       setVisible={setAddBlockVisible} onAddBlock={handleAddBlock}/>}
                    {editBlockState.visible &&
                        <EditBlockModal editState={editBlockState} setEditState={setEditBlockState}
                                        onEditBlock={handleEditBlock}/>}
                </div>
            </div>
    </MainLayout>
)
}

export default Dashboards
